import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['languageContainer', 'cancellationContainer', 'maxHour', 'refundDeduction'];

  static values = {
    url: String,
    model: String,
    defaultRowCount: Number
  }

  connect() {
    this.rowCount = this.defaultRowCountValue;
  }

  buildQueryURL() {
    const url = new URL(this.urlValue);
    url.searchParams.set('model_name', this.modelValue);
    url.searchParams.set('row_count', this.rowCount);
    url.searchParams.set('targets', JSON.stringify(this.languageContainerTargets.map(target => target.id)));
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  inputMaxHour(event) {
    for (const target of this.maxHourTargets) {
      if (event.currentTarget.dataset.cancelFormIndex === target.dataset.cancelFormIndex) {
        target.value = event.target.value
      }
    }
  }

  inputRefundDeduction(event) {
    for (const target of this.refundDeductionTargets) {
      if (event.currentTarget.dataset.cancelFormIndex === target.dataset.cancelFormIndex) {
        target.value = event.target.value
      }
    }
  }

  add(event) {
    event.preventDefault();

    const url = this.buildQueryURL();
    if (!url) return;

    Turbo.visit(url, {
      historyChanged: true
    });

    this.rowCount++
  }

  remove(event) {
    event.preventDefault();
    for(const cancellationContainer of this.cancellationContainerTargets) {
      if (cancellationContainer.dataset.cancelRow === event.currentTarget.dataset.cancelRow) {
        cancellationContainer.remove()
      }
    }
  }
}