import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'error-icon', 'action'];

  actionTargetConnected(element) {
    const actionType = element.dataset.actionType;
    const fn = this[actionType].bind(this);
    if (typeof fn === 'function') {
      fn(element);
    } else {
      console.error(`${actionType} is not a valid data-action-type`);
    }
    element.remove();
  }

  addClass(element) {
    const value = element.dataset.value;
    this.selectedTargets(element).forEach((target) => target.classList.add(value));
  }

  removeClass(element) {
    const value = element.dataset.value;
    this.selectedTargets(element).forEach((target) => target.classList.remove(value));
  }

  setAttr(element) {
    const value = JSON.parse(element.dataset.value);
    const attrName = value.name;
    const attrValue = value.value;
    this.selectedTargets(element).forEach((target) => target.setAttribute(attrName, attrValue));
  }

  removeAttr(element) {
    const value = element.dataset.value;
    this.selectedTargets(element).forEach((target) => target.removeAttribute(value));
  }

  dispatch(element) {
    const event = element.dataset.event;
    const value = element.dataset.value;
    window.dispatchEvent(new CustomEvent(event, { detail: value }));
  }

  selectedTargets(element) {
    const targets = element.dataset.targets;
    if (targets && targets !== '') {
      return document.querySelectorAll(targets);
    }

    const target = element.dataset.target;
    const targetEl = document.getElementById(target);
    if (targetEl == null) {
      return [];
    }

    return [targetEl];
  }
}
