import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  connect() {
    super.connect();
    const initialOpened = (this.data.get('initialOpened') || 'true') === 'true';
    if (initialOpened) {
      this.open({ target: this.element, preventDefault: () => {} });
    }
  }
}
