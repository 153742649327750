import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapsible', 'collapseIcon', 'expandIcon']
  maxHeightValue = '5000px'

  connect() {
    this.collapsibleTarget.style.maxHeight = this.maxHeightValue
    if (this.collapsibleTarget.dataset.expanded === "0") {
      this.collapse()
    }
  }

  toggle() {
    if (this.collapsibleTarget.dataset.expanded === "1") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    this.collapsibleTarget.style.maxHeight = 0
    this.collapsibleTarget.dataset.expanded = "0"
    this.collapseIconTarget.classList.add("hidden")
    this.expandIconTarget.classList.remove("hidden")
  }

  expand() {
    this.collapsibleTarget.style.maxHeight = this.maxHeightValue
    this.collapsibleTarget.dataset.expanded = "1"
    this.collapseIconTarget.classList.remove("hidden")
    this.expandIconTarget.classList.add("hidden")
  }
}
