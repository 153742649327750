import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['actionButton', 'actionButtonSpinner', 'actionButtonText']

  startAction() {
    this.actionButtonTargets.forEach((target) => {
      target.classList.add('cursor-not-allowed');
      target.setAttribute('disabled', '');
    });
    this.actionButtonSpinnerTargets.forEach((target) => {
      target.classList.remove('hidden');
    });
    this.actionButtonTextTargets.forEach((target) => {
      target.classList.add('invisible');
    });
  }

  stopAction() {
    this.actionButtonTargets.forEach((target) => {
      target.classList.remove('cursor-not-allowed');
      target.removeAttribute('disabled');
    });
    this.actionButtonSpinnerTargets.forEach((target) => {
      target.classList.add('hidden');
    });
    this.actionButtonTextTargets.forEach((target) => {
      target.classList.remove('invisible');
    });
  }

  submitForm(evt) {
    evt.preventDefault();
    const el = evt.currentTarget;
    const formId = el.dataset.formTarget;
    const form = document.getElementById(formId);
    this.startAction();
    setTimeout(() => {
      Turbo.navigator.submitForm(form);
    });
  }

  visit(evt) {
    const el = evt.currentTarget;
    const target = el.dataset.visitTarget;
    this.startAction();
    setTimeout(() => {
      Turbo.visit(target, { historyChanged: true });
    });
  }
}
