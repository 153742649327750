import { Controller } from "@hotwired/stimulus";
import { addClasses } from "./classList";
import { useTransition } from "./mixins/useTransition";

// this needs to be in sync with alert_icon_component.rb
const ICON_TYPE = {
  notice: 'far fa-check-circle text-green-400',
  error: 'far fa-exclamation-circle text-red-400',
  inProgress: 'fas fa-spinner fa-spin',
};

export default class extends Controller {
  static targets = ['action', 'content', 'icon'];
  static values = {
    hidden: { type: Boolean, default: true },
    dismissAfter: Number,
  };

  initialize() {
    useTransition(this);
  }

  connect() {
    if (this.hiddenValue) {
      this.hide(true);
    } else {
      this.show();
    }
  }

  disconnect() {
    this.cancelDelayedDismiss();
    this.cancelTransitions();
  }

  show(type, content) {
    if (type != null) {
      this.iconTarget.className = ICON_TYPE[type];
    }
    if (content != null) {
      this.contentTarget.innerHTML = content;
    }
    this.cancelDelayedDismiss();
    this.cancelTransitions();
    this.hide(true);
    this.runTransition('leave', 'enter');

    // Auto dimiss if defined
    if (this.hasDismissAfterValue) {
      this.element.dismissTimeoutToken = setTimeout(() => {
        this.element.dataset.alertHiddenValue = true;
      }, this.dismissAfterValue);
    }
  }

  hide(immediate) {
    this.cancelDelayedDismiss();
    this.cancelTransitions();
    if (immediate === true) {
      addClasses(this.transitionTarget(), `${this.transitions('leave').to} ${this.transitions('leave').finished}`);
      return;
    }

    this.runTransition('enter', 'leave');
  }

  hiddenValueChanged() {
    if (this.hiddenValue) {
      this.hide();
    } else {
      this.show();
    }
  }

  cancelDelayedDismiss() {
    if (this.element.dismissTimeoutToken) {
      clearTimeout(this.element.dismissTimeoutToken);
      this.element.dismissTimeoutToken = null;
    }
  }
}
