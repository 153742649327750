import { Controller } from '@hotwired/stimulus';
import { visit } from '@hotwired/turbo';

export default class extends Controller {
  static targets = ['input']
  static values = {
    addUrl: String,
    removeUrl: String,
  }

  add() {
    const url = new URL(this.addUrlValue);
    const lastItem = this.inputTargets[this.inputTargets.length - 1];
    if (lastItem == null) {
      url.searchParams.set('next', 0);
    } else {
      const lastIndex = parseInt(lastItem.dataset.index, 10);
      url.searchParams.set('next', lastIndex + 1);
    }

    visit(url.toString(), { historyChanged: true });
  }

  remove(el) {
    const url = new URL(this.removeUrlValue);
    url.searchParams.set('index', el.target.dataset.itemIndex);
    visit(url.toString(), { historyChanged: true });
  }
}
