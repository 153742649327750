import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['min', 'max'];

  minChanged(evt) {
    if (this.maxTarget.value < evt.target.value) {
      this.maxTarget.value = null;
    }
    this.maxTarget.min = evt.target.value;
  }
}
