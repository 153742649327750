import { Controller as BaseController } from '@hotwired/stimulus';
import { navigator } from "@hotwired/turbo";

class Controller extends BaseController {
  static targets = ['form', 'error', 'success', 'dryRunOnlyInput', 'fullNameInput', 'emailInput', 'fullNameDisplay', 'emailDisplay'];

  updateDisplay() {
    this.fullNameDisplayTarget.innerHTML = this.fullNameInputTarget.value;
    this.emailDisplayTarget.innerHTML = this.emailInputTarget.value;
  }

  drySubmitForm() {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    this.dryRunOnlyInputTarget.value = true;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }

  submitForm() {
    this.dryRunOnlyInputTarget.value = false;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }
}

export default application => application.register('admin-users-new', Controller);
