import { Controller as BaseController } from '@hotwired/stimulus';
import { navigator } from "@hotwired/turbo";
import { useFileUpload } from '../../fileUpload';

class Controller extends BaseController {
  static targets = [
    'form',
    'allowDraftInput',
    'input',
    'submitButton',
    'fileInput',
    'fileUpload',
    'additionalInfo'
  ];

  connect() {
    useFileUpload(this);
    this.connectFileUpload();
  }

  disconnect() {
    this.disconnectFileUpload();
  }

  onChangeValue(element) {
    if (element.target.value === 'true') {
      this.additionalInfoTarget.classList.remove('hidden');
      return;
    }
    this.additionalInfoTarget.classList.add('hidden');
  }

  submitForm() {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    this.allowDraftInputTarget.value = false;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }

  allowDraftSubmitForm() {
    this.allowDraftInputTarget.value = true;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }
}

export default application => application.register('admin-package_groups-new', Controller);
