import { addClasses, removeClasses } from "./classList";

export function transition(el, from, to, cancellationTokens) {
  function wait(timeout) {
    return new Promise(resolve => {
      const token = setTimeout(() => {
        const index = cancellationTokens.indexOf(token);
        cancellationTokens.splice(index, 1);
        resolve();
      }, timeout);
      cancellationTokens.push(token);
    });
  }

  wait(to.delay)
    .then(() => {
      el.classList.add(`duration-${to.duration}`);
      removeClasses(el, from.base);
      removeClasses(el, from.finished);
      removeClasses(el, from.to);
      addClasses(el, to.base);
      addClasses(el, to.from);
      return wait();
    })
    .then(() => {
      removeClasses(el, to.from);
      addClasses(el, to.to);
      return wait(to.duration);
    })
    .then(() => {
      addClasses(el, to.finished);
      el.classList.remove(`duration-${to.duration}`);
    });
}
