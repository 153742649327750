import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['labelContainer', 'label', 'input', 'menuContainer', 'valueInput'];

  static values = {
    url: String,
    suggestion: String,
  }

  valueInputTargetConnected() {
    this.selectedIds = this.valueInputTarget.value.split(',').map((v) => v.trim());
  }

  buildQueryURL(id, name) {
    const url = new URL(this.urlValue);
    url.searchParams.set('id', id);
    url.searchParams.set('name', name);
    url.searchParams.set('target', this.labelContainerTarget.id);
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  buildSuggestionQueryURL(search) {
    const url = new URL(this.suggestionValue);
    url.searchParams.set('search', search);
    url.searchParams.set('target', this.menuContainerTarget.id);
    url.searchParams.set('selected_ids', JSON.stringify(this.selectedIds));
    url.searchParams.set('item_name', this.inputTargets.length > 0 ? this.inputTargets[0].name : "");
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  search(event) {
    event.preventDefault();
    const url = this.buildSuggestionQueryURL(event.currentTarget.value);
    if (!url) return;

    Turbo.visit(url, {
      historyChanged: true
    });
  }

  check(event) {
    event.preventDefault();
    if (!event.currentTarget.checked) {
      for (const label of this.labelTargets) {
        const { id } = label.dataset;
        if (id === event.currentTarget.dataset.id) {
          this.selectedIds = this.selectedIds.filter(item => item !== id)
          label.remove();
        }
      }

      this.valueInputTarget.value = this.selectedIds.join(', ');
      this.valueInputTarget.dispatchEvent(new Event('change'));
      return;
    }

    const { id, name } = event.currentTarget.dataset;

    const url = this.buildQueryURL(id, name);
    if (!url) return;

    this.selectedIds.push(id);
    this.valueInputTarget.value = this.selectedIds.join(', ');
    this.valueInputTarget.dispatchEvent(new Event('change'));

    Turbo.visit(url, {
      historyChanged: true
    });
  }

  remove(event) {
    event.preventDefault();
    for (const input of this.inputTargets) {
      if (input.dataset.id === event.currentTarget.parentNode.dataset.id) {
        this.selectedIds = this.selectedIds.filter(item => item !== input.dataset.id)
        input.checked = false;
      }
    }
    event.currentTarget.parentNode.remove();
    this.valueInputTarget.value = this.selectedIds.join(', ');
    this.valueInputTarget.dispatchEvent(new Event('change'));
  }
}
