import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['timeslot', 'start', 'end'];
  connect() {

  }

  buildQueryURL() {
    const url = new URL(this.data.get("url"));
    const rowCount = this.timeslotTarget.children.length
    const startName = this.startTarget.name.replace('0', rowCount)
    const endName = this.endTarget.name.replace('0', rowCount)
    url.searchParams.set('start', startName);
    url.searchParams.set('end', endName);
    url.searchParams.set('target', this.timeslotTarget.id);
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  add(event) {
    event.preventDefault();

    const url = this.buildQueryURL();
    if (!url) return;

    Turbo.visit(url, {
      historyChanged: true
    });
  }
}
