import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'timeslot',
    'timeslotInput',
    'inventory',
    'availableDate',
    'availableDateInput',
    'room',
    'quantityDeductionMode'
  ];

  connect() {

  }

  selectType(event) {
    event.preventDefault();
    const room = this.roomTarget;
    const timeSlot = this.timeslotTarget;
    const inventory = this.inventoryTarget;
    const availableDate = this.availableDateTarget;
    inventory.setAttribute("data-add-on-inventory-add-on-type-value", event.currentTarget.value);
    switch (event.currentTarget.value) {
      case "extra_visitor":
        room.classList.add("hidden");
        inventory.classList.add("hidden");
        availableDate.classList.add("hidden");
        timeSlot.classList.add("hidden");
        break;
      case "extra_bed":
        room.classList.add("hidden");
        inventory.classList.remove("hidden");
        availableDate.classList.add("hidden");
        timeSlot.classList.add("hidden");
        break;
      case "room_upgrade":
        room.classList.remove("hidden");
        inventory.classList.remove("hidden");
        availableDate.classList.add("hidden");
        timeSlot.classList.add("hidden");
        break;
      case "others":
        room.classList.add("hidden");
        inventory.classList.remove("hidden");
        availableDate.classList.remove("hidden");
        timeSlot.classList.remove("hidden");
        break;
      default:
        room.classList.add("hidden");
        timeSlot.classList.add("hidden");
        inventory.classList.add("hidden");
        availableDate.classList.add("hidden");
        break;
    }
  }

  onChangeEnableAvailableDate(el) {
    const enable = el.target.value;
    if (enable === 'true') {
      this.availableDateInputTargets.forEach((input) => input.removeAttribute('disabled'));
    } else {
      this.availableDateInputTargets.forEach((input) => {
        input.setAttribute('disabled', 'true');
      });
    }
  }

  onChangeEnableTimeSlot(el) {
    const enable = el.target.value;
    if (enable === 'true') {
      this.timeslotInputTargets.forEach((input) => input.removeAttribute('disabled'));
    } else {
      this.timeslotInputTargets.forEach((input) => {
        input.setAttribute('disabled', 'true');
      });
    }
  }
}
