import { Controller } from '@hotwired/stimulus';
import { RRule } from 'rrule';

export default class extends Controller {
  static targets = [
    'rruleValue',
    'rruleDescription',
  ]

  connect() {}

  rruleValueTargetConnected() {
    if (this.rruleValueTarget.value === '') {
      return;
    }
    const rrule = RRule.fromString(this.rruleValueTarget.value);
    this.rruleValueTarget.value =  rrule.toString();
    const text = rrule.toText();
    this.rruleDescriptionTarget.innerHTML = text.charAt(0).toUpperCase() + text.slice(1);
    this.rruleDescriptionTarget.classList.remove('hidden');
  }
}
