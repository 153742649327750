import Sortable from "stimulus-sortable";

export default class extends Sortable {
  static targets = ['dirty'];

  // You can override the `end` method here.
  end({ item, newIndex  }) {
    if (this.dirtyTarget) {
      this.dirtyTarget.setAttribute('value', true);
      this.dirtyTarget.dispatchEvent(new Event('change'));
    }
    super.end({ item, newIndex });
  }
}
