export function addClasses(el, classes) {
  if (classes == null || classes.trim() === '') {
    return;
  }

  classes.trim().split(' ').forEach((c) => el.classList.add(c));
}

export function removeClasses(el, classes) {
  if (classes == null || classes.trim() === '') {
    return;
  }

  classes.trim().split(' ').forEach((c) => el.classList.remove(c));
}
