import { Controller } from '@hotwired/stimulus';
import { RRule } from 'rrule';

const RRULE_WEEKDAYS = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

export default class extends Controller {
  static targets = [
    'rruleValue',
    'rruleOption',
    'rruleDescription',
    'interval',
    'intervalUnit',
    'intervalOptions',
    'intervalWeekOption',
    'intervalMonthOption',
    'frequencyContinuous',
    'frequencyRepeat',
    'frequencyRepeatCount',
    'endDate',
    'endDateDisplay',
  ]

  connect() {
    this.data.set('oldRRuleOptionValue', 'every_day');
  }

  rruleValueTargetConnected() {
    if (this.endDateTarget.value !== '') {
      this.endDateDisplayTarget.innerHTML = this.endDateTarget.value;
    } else {
      this.endDateDisplayTarget.innerHTML = '-';
    }
    if (this.rruleValueTarget.value === '') {
      return;
    }
    const rrule = RRule.fromString(this.rruleValueTarget.value);
    this.rruleValueTarget.value =  rrule.toString();
    const text = rrule.toText();
    this.rruleDescriptionTarget.innerHTML = text.charAt(0).toUpperCase() + text.slice(1);
    this.rruleDescriptionTarget.classList.remove('hidden');
    this.rruleOptionTarget.value = 'current_rrule';
    this.data.set('oldRRuleOptionValue', 'current_rrule');
  }

  recurrenceChanged(evt) {
    if (evt.target.value === 'custom') {
      this.start();
      const controller = this.application.getControllerForElementAndIdentifier(this.element, 'calendar');
      controller.openRecurrenceModal();
      this.rruleOptionTarget.value = this.data.get('oldRRuleOptionValue');
    } else {
      this.data.set('oldRRuleOptionValue', evt.target.value);
    }
  }

  intervalUnitChanged(evt) {
    this.intervalOptionsTargets.forEach((t) => {
      if (t.dataset.intervalOptions === evt.target.value) {
        t.classList.remove('hidden');
      } else {
        t.classList.add('hidden');
      }
    });
  }

  endDateChanged(evt) {
    const date = evt.target.value;
    this.endDateDisplayTarget.innerHTML = date;
  }

  frequencyChanged(evt) {
    const value = evt.target.value;
    if (value === 'repeat') {
      this.frequencyRepeatCountTarget.removeAttribute('disabled');
    } else {
      this.frequencyRepeatCountTarget.setAttribute('disabled', true);
    }
  }

  start() {
    if (this.rruleValueTarget.value === '') {
      return;
    }
    try {
      const rrule = RRule.fromString(this.rruleValueTarget.value);
      this.intervalTarget.value = rrule.options.interval;
      this.intervalUnitTarget.value = {
        [RRule.DAILY]: 'day',
        [RRule.WEEKLY]: 'week',
        [RRule.MONTHLY]: 'month',
        [RRule.YEARLY]: 'year',
      }[rrule.options.freq];
      if (this.intervalUnitTarget.value === 'week') {
        this.intervalWeekOptionTargets.forEach((t, index) => {
          t.dataset.toggleFieldValueValue = rrule.options.byweekday.indexOf(RRULE_WEEKDAYS[index].weekday) !== -1 ? 'true' : 'false';
        });
      } else if (this.intervalUnitTarget.value === 'month') {
        this.intervalMonthOptionTarget.value = rrule.options.bymonthday;
      }
      if (rrule.options.count != null) {
        this.frequencyRepeatTarget.checked = true;
        this.frequencyRepeatCountTarget.value = rrule.options.count;
        this.frequencyRepeatCountTarget.removeAttribute('disabled');
      } else {
        this.frequencyContinuousTarget.checked = true;
      }

      this.intervalOptionsTargets.forEach((t) => {
        if (t.dataset.intervalOptions === this.intervalUnitTarget.value) {
          t.classList.remove('hidden');
        } else {
          t.classList.add('hidden');
        }
      });
    } catch (e) {
      console.log('failed parsing rrule', e);
    }
  }

  finish() {
    const rrule = this.toRRule();
    this.rruleValueTarget.value =  rrule.toString();
    const text = rrule.toText();
    this.rruleDescriptionTarget.innerHTML = text.charAt(0).toUpperCase() + text.slice(1);
    this.rruleDescriptionTarget.classList.remove('hidden');
    this.rruleOptionTarget.value = 'current_rrule';
    this.data.set('oldRRuleOptionValue', 'current_rrule');
  }

  toRRule() {
    const config = {};
    config.freq = {
      day: RRule.DAILY,
      week: RRule.WEEKLY,
      month: RRule.MONTHLY,
      year: RRule.YEARLY,
    }[this.intervalUnitTarget.value];
    config.interval = parseInt(this.intervalTarget.value, 10);
    if (this.intervalUnitTarget.value === 'week') {
      config.byweekday = [];
      this.intervalWeekOptionTargets.forEach((t, index) => {
        if (t.dataset.toggleFieldValueValue === 'true') {
          config.byweekday.push(RRULE_WEEKDAYS[index]);
        }
      });
    } else if (this.intervalUnitTarget.value === 'month') {
      config.bymonthday = parseInt(this.intervalMonthOptionTarget.value, 10);
    }
    if (this.frequencyRepeatTarget.checked) {
      config.count = parseInt(this.frequencyRepeatCountTarget.value, 10);
    }
    return new RRule(config);
  }
}
