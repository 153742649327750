// modified from https://github.com/afcapel/stimulus-autocomplete/blob/main/src/autocomplete.js
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const optionSelector = "[role='option']:not([aria-disabled])";
const activeSelector = "[aria-selected='true']";

class Autocomplete extends Controller {
  static targets = ["input", "valueInput", "labelInput", "results"];
  static classes = ["selected"];
  static values = {
    ready: Boolean,
    submitOnEnter: Boolean,
    url: String,
    resultId: String,
    minLength: Number,
    /*
     * Should we skip adding/removing the "hidden" property from resultsTarget?
     *
     * If set, you must listen to the "toggle" event from this
     * controller to manually show/hide the results target.
     */
    skipHiddenProperty: Boolean,
    alwaysClearInputOnFocus: Boolean,
  };

  connect() {
    this.close();

    if (!this.inputTarget.hasAttribute("autocomplete"))
      this.inputTarget.setAttribute("autocomplete", "off");
    this.inputTarget.setAttribute("spellcheck", "false");

    this.mouseDown = false;

    this.inputTarget.addEventListener("keydown", this.onKeydown);
    this.inputTarget.addEventListener("focus", this.onInputFocus);
    this.inputTarget.addEventListener("blur", this.onInputBlur);
    this.inputTarget.addEventListener("input", this.onInputChange);
    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("click", this.onResultsClick);

    if (this.inputTarget.hasAttribute("autofocus")) {
      this.inputTarget.focus();
    }

    this.readyValue = true;
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.onKeydown);
      this.inputTarget.removeEventListener("focus", this.onInputFocus);
      this.inputTarget.removeEventListener("blur", this.onInputBlur);
      this.inputTarget.removeEventListener("input", this.onInputChange);
    }

    if (this.hasResultsTarget) {
      this.resultsTarget.removeEventListener(
        "mousedown",
        this.onResultsMouseDown
      );
      this.resultsTarget.removeEventListener("click", this.onResultsClick);
    }
  }

  sibling(next) {
    const options = this.options;
    const selected = this.selectedOption;
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    const previouslySelected = this.selectedOption;
    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected");
      previouslySelected.classList.remove(...this.selectedClassesOrDefault);
    }

    target.setAttribute("aria-selected", "true");
    target.classList.add(...this.selectedClassesOrDefault);
    this.inputTarget.setAttribute("aria-activedescendant", target.id);
    target.scrollIntoView(false);
  }

  onKeydown = (event) => {
    const handler = this[`on${event.key}Keydown`];
    if (handler) handler(event);
  };

  onEscapeKeydown = (event) => {
    if (this.isHidden) return;

    this.hideAndRemoveOptions();
    this.inputTarget.blur();
    event.stopPropagation();
    event.preventDefault();
  };

  onArrowDownKeydown = (event) => {
    const item = this.sibling(true);
    if (item) this.select(item);
    event.preventDefault();
  };

  onArrowUpKeydown = (event) => {
    const item = this.sibling(false);
    if (item) this.select(item);
    event.preventDefault();
  };

  onTabKeydown = (event) => {
    const selected = this.selectedOption;
    if (selected) this.commit(selected);
  };

  onEnterKeydown = (event) => {
    const selected = this.selectedOption;
    if (selected && !this.isHidden) {
      this.commit(selected);
      if (!this.hasSubmitOnEnterValue) {
        event.preventDefault();
      }
    }
  };

  onInputBlur = () => {
    if (this.mouseDown) return;
    this.inputTarget.value = this.labelInputTarget.value;
    this.close();
  };

  onInputFocus = () => {
    if (this.alwaysClearInputOnFocusValue || this.valueInputTarget.value === '') {
      this.inputTarget.value = '';
    }
    this.fetchResults();
    this.open();
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return;

    if (selected instanceof HTMLAnchorElement) {
      selected.click();
      this.close();
      return;
    }

    const textValue =
      selected.getAttribute("data-autocomplete-label") ||
      selected.textContent.trim();
    const value = selected.getAttribute("data-autocomplete-value");
    this.inputTarget.value = textValue;

    this.valueInputTarget.value = value;
    this.labelInputTarget.value = textValue;

    this.inputTarget.focus();
    this.hideAndRemoveOptions();

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue },
      })
    );
  }

  clear() {
    this.inputTarget.value = "";
    if (this.hasHiddenTarget) this.hiddenTarget.value = "";
  }

  onResultsClick = (event) => {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest(optionSelector);
    if (selected) this.commit(selected);
    this.inputTarget.blur();
  };

  onResultsMouseDown = () => {
    this.mouseDown = true;
    this.resultsTarget.addEventListener(
      "mouseup",
      () => {
        this.mouseDown = false;
      },
      { once: true }
    );
  };

  onInputChange = debounce(() => {
    this.element.removeAttribute("value");
    if (this.hasHiddenTarget) this.hiddenTarget.value = "";
    this.fetchResults();
    this.open();
  }, 300);

  hideAndRemoveOptions() {
    this.close();
    this.resultsTarget.innerHTML = null;
  }

  fetchResults() {
    if (!this.hasUrlValue) return;

    const url = this.buildQueryURL();
    if (!url) return;

    Turbo.visit(url, {
      historyChanged: true
    });
  }

  buildQueryURL() {
    const url = new URL(this.urlValue);
    url.searchParams.set('search', this.inputTarget.value.trim());
    url.searchParams.set('target', this.resultIdValue);
    url.pathname = `${url.pathname}.turbo_stream`;
    return url.toString();
  }

  open() {
    if (!this.isHidden) return;
    if (!this.hasSkipHiddenPropertyValue) {
      this.resultsTarget.hidden = false;
    }
    this.isHidden = false;
    this.element.setAttribute("aria-expanded", "true");
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: {
          action: "open",
          inputTarget: this.inputTarget,
          resultsTarget: this.resultsTarget,
        },
      })
    );
  }

  close() {
    if (this.isHidden) return;
    if (!this.hasSkipHiddenPropertyValue) {
      this.resultsTarget.hidden = true;
    }
    this.isHidden = true;
    this.inputTarget.removeAttribute("aria-activedescendant");
    this.element.setAttribute("aria-expanded", "false");
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: {
          action: "close",
          inputTarget: this.inputTarget,
          resultsTarget: this.resultsTarget,
        },
      })
    );
  }

  get options() {
    return Array.from(this.resultsTarget.querySelectorAll(optionSelector));
  }

  get selectedOption() {
    return this.resultsTarget.querySelector(activeSelector);
  }

  get selectedClassesOrDefault() {
    return this.hasSelectedClass ? this.selectedClasses : ["active"];
  }

  headersForFetch() {
    return { "X-Requested-With": "XMLHttpRequest" }; // override if you need
  }
}

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

export default (application) => application.register("autocomplete", Autocomplete);
