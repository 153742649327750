// https://gist.github.com/lazaronixon/dca1b48c241422d6347f4b0c93bec739
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";
import { getMetaValue, removeElement, insertAfter, readFileBuffer, checkFileHeaders } from "./helpers";

Dropzone.autoDiscover = false;

export const useFileUpload = controller => {
  return Object.assign(controller, {
    connectFileUpload() {
      controller.dropZone = createDropZone(controller)
      controller.hideFileInput();
      controller.bindEvents(controller.dropZone);
      controller.reorderImage();
      const imageBlobs = JSON.parse(controller.fileUploadTarget.dataset.imageBlobs);
      const imageUrls = JSON.parse(controller.fileUploadTarget.dataset.imageUrls);
      const imageDescriptions = JSON.parse(controller.fileUploadTarget.dataset.imageDescriptions);
      for (let index = 0; index < imageBlobs.length; index++) {
        const blob = imageBlobs[index];
        const url = imageUrls[index];
        const descriptions = imageDescriptions[index];
        const file = {
          attachmentId: blob.id,
          name: blob.filename,
          size: blob.byte_size,
          url: url,
          mock: true,
          blob: blob,
          accepted: true,
          status: Dropzone.SUCCESS,
          descriptions: descriptions,
        };
        controller.dropZone.displayExistingFile(file, url, null, 'anonymous');
        controller.dropZone.files.push(file);
      }

      // listen for sortable change
      const sortingDirtyEl = controller.element.querySelector('#image-upload-sorting-dirty');
      if (sortingDirtyEl) {
        sortingDirtyEl.addEventListener('change', controller.reorderImage);
      }
    },
    disconnectFileUpload() {
      const sortingDirtyEl = controller.element.querySelector('#image-upload-sorting-dirty');
      if (sortingDirtyEl) {
        sortingDirtyEl.removeEventListener('change', controller.reorderImage);
      }
    },
    markDirty() {
      const dirtyEl = controller.element.querySelector('#image-upload-dirty');
      if (dirtyEl) {
        dirtyEl.dispatchEvent(new Event('change'));
      }
    },
    remove(event) {
      event.stopPropagation();
      event.preventDefault();

      const target = document.getElementById(`previewContainer-${event.target.dataset.attachmentId}`)
      target.remove();

      const imageContainers = document.getElementsByClassName("description-image-container");
      for (const container of imageContainers) {
        const { index } = container.dataset;
        document.getElementById(`description-container-${index}-${target.dataset.attachmentId}`).remove();
      }
      controller.reorderImage();
    },
    hideFileInput() {
      controller.fileInputTarget.disabled = true;
      controller.fileInputTarget.style.display = "none";
    },
    addDescriptionImage(file) {
      const imageContainers = document.getElementsByClassName('description-image-container');
      for (const container of imageContainers) {
        const { index } = container.dataset;
        const descriptionContainer = document.createElement("div");
        descriptionContainer.id = `description-container-${index}-${file.controller.attachmentId}`;
        descriptionContainer.className = `description-container-${file.controller.attachmentId}`;
        const img = document.createElement("img");
        img.id = `img-${file.controller.attachmentId}`;
        img.classList.add('description-image');
        if (file.dataURL) {
          img.src = file.dataURL;
        } else {;
          img.src = window.fileNotFound;
        }
        descriptionContainer.appendChild(img)
        const input = document.createElement("input");
        input.classList.add('text-input');
        input.classList.add('mt-5');
        input.classList.add('w-picture')
        input.name = `${controller.fileUploadTarget.dataset.tag}[localized_properties_attributes][${index}][images][${file.controller.attachmentId}]`;
        input.value = (file.descriptions || {})[index] || '';
        input.setAttribute('data-prevent-leave-dirty-form-target', 'input');
        descriptionContainer.appendChild(input)
        const placeholder = document.getElementById("description-placeholder");
        if (placeholder) {
          placeholder.remove();
        }
        container.appendChild(descriptionContainer);
      }
    },

    reorderImage() {
      const children = document.getElementById('sortable-container').childNodes
      const order = []
      for (const child of children) {
        if (child.classList && child.classList.contains('preview-container')) {
          order.push(child.dataset.attachmentId);
        }
      }
      let hiddenSortOrderInput = document.getElementById('hidden-sort-order-input');
      if (!hiddenSortOrderInput) {
        hiddenSortOrderInput = document.createElement("input");
        hiddenSortOrderInput.id = 'hidden-sort-order-input';
        hiddenSortOrderInput.type = "hidden";
        hiddenSortOrderInput.name = `${controller.fileUploadTarget.element.dataset.tag}[sort_order]`;
        insertAfter(hiddenSortOrderInput, document.getElementById('file-upload-container'))
      }
      hiddenSortOrderInput.value = JSON.stringify(order)

      const imageContainers = document.getElementsByClassName("description-image-container");
      for (const container of imageContainers) {
        const { index } = container.dataset;
        for (const o of order) {
          const descriptionContainer = document.getElementById(`description-container-${index}-${o}`);
          if (descriptionContainer) {
            container.appendChild(descriptionContainer);
          }
        }
      }
    },

    // try best to provide human friendly error message
    parseErrorMessage(message) {
      let output = message;
      output = output.replace('Status: 413', 'File too large.');
      return output;
    },

    showErrorMessage(message) {
      const imageErrorSpan = document.getElementById('image-error');
      imageErrorSpan.textContent = controller.parseErrorMessage(message);
      imageErrorSpan.classList.remove('hidden');
    },

    bindEvents(dropZone) {
      dropZone.on("addedfile", async (file) => {
        if (!file.mock) {
          const isPNG = checkFileHeaders([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
          const isJPEG = checkFileHeaders([0xff, 0xd8, 0xff]);
          const buffers = await readFileBuffer(file, 0, 8);
          const uint8Array = new Uint8Array(buffers);
          if (!(isPNG(uint8Array) || isJPEG(uint8Array))) {
            const index = controller.dropZone.files.indexOf(file);
            if (index !== -1) {
              controller.dropZone.files.splice(index, 1);
            }
            controller.showErrorMessage('Unsupported file type. Accept images only.');
            file.previewElement.remove();
            return;
          }
        }

        this.markDirty();

        controller.submitButtonTarget.disabled = true;
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
        }, 500);
        document.getElementById("sortable-container").appendChild(file.previewElement);
      });

      dropZone.on("removedfile", (file) => {
        if (!file.controller) {
          return;
        }

        this.markDirty();

        const imageContainers = document.getElementsByClassName("description-image-container");
        for (const container of imageContainers) {
          const { index } = container.dataset;
          document.getElementById(`description-container-${index}-${file.controller.attachmentId}`).remove()
        }

        removeElement(file.controller.hiddenInput);
        controller.reorderImage();
      });

      dropZone.on("canceled", (file) => {
        file.controller && file.controller.xhr.abort();
      });

      dropZone.on("error", (file, response) => {
        const index = controller.dropZone.files.indexOf(file);
        if (index !== -1) {
          controller.dropZone.files.splice(index, 1);
        }
        controller.showErrorMessage(response);
      });

      dropZone.on("success", (file, addToDescription) => {
        document.getElementById('image-error').classList.add('hidden');
        file.previewElement.dataset.attachmentId = file.controller.attachmentId;
        const img = file.previewElement.querySelector('img');
        if (img.src == null || img.src === '') {
          img.src = window.fileNotFound;
        }
        if (addToDescription) {
          controller.addDescriptionImage(file);
        }
      });

      dropZone.on("dragend", () => {
        controller.reorderImage();
        this.markDirty();
      });

      dropZone.on("thumbnail", (file, _) => {
        if (file.mock && file.url) {
          const img = file.previewElement.querySelector('img');
          img.src = file.url;
        }
      });

      dropZone.on("queuecomplete", (file) => {
        controller.reorderImage();
        controller.submitButtonTarget.disabled = false;
      });
    },
    headers() {
      return { "X-CSRF-Token": getMetaValue("csrf-token") };
    },
    url() {
      return controller.fileInputTarget.getAttribute("data-direct-upload-url");
    }
  });
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url(source.fileInputTarget), this);
    this.source = source;
    this.file = file;
    this.attachmentId = null;
  }

  start() {
    this.file.controller = this;
    if (this.file.mock) {
      this.attachmentId = this.file.blob.id;
      this.emitDropzoneSuccess(false);
      return;
    }

    this.hiddenInput = this.createHiddenInput();

    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.hiddenInput.id = `signed-id-${attributes.id}`
        this.attachmentId = attributes.id
        this.emitDropzoneSuccess(true);
      }
    });
  }

  // Private
  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.fileInputTarget.name;
    insertAfter(input, this.source.fileInputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess(addToDescription) {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file, addToDescription);
    this.source.dropZone.emit("complete", this.file);
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

export function createDropZone(controller) {
  const dropzoneEl = controller.element.querySelector('#dropzone-container');
  const url = controller.url();
  const headers = controller.headers();
  return new Dropzone(dropzoneEl, {
    url,
    headers,
    maxFiles: 100,
    maxFilesize: 256,
    acceptedFiles: '.jpeg,.jpg,.png,image/jpg,image/jpeg,image/png',
    addRemoveLinks: false,
    autoQueue: false,
    previewTemplate: document.querySelector('#file-upload-preview').innerHTML,
    thumbnailWidth: 255,
    thumbnailHeight: 170,
  });
}
