import { Controller as BaseController } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

class Controller extends BaseController {
  static targets = ['searchForm']

  submitSearchForm() {
    setTimeout(() => {
      Turbo.navigator.submitForm(this.searchFormTarget);
    });
  }
}

export default application => application.register('admin-orders-index', Controller);
