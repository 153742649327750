// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import { Application } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Turbo.session.drive = false;

const application = Application.start();
application.warnings = true;
application.debug = false;
window.Stimulus = application;

import LocalTime from "local-time"
LocalTime.start()

import ApplicationController from '../components/application';
import ToggleField from "../components/toggleField";
import Alert from "../components/alert";
import Responder from "../components/responder";
import PreventLeaveDirtyForm from "../components/preventLeaveDirtyForm";
import Modal from "../components/modal";
import Dropdown from "../components/dropdown";
import InputList from "../components/inputList";
import CountryCitySelect from "../components/countryCitySelect";
import CountryCityCheckbox from "../components/countryCityCheckbox";
import Tabs from "../components/tabs";
import Calendar from "../components/calendar";
import TimeSlot from "../components/timeSlot";
import AddOnTypeSelect from "../components/addOnTypeSelect";
import AddOnInventory from "../components/addOnInventory";
import DateRange from "../components/dateRange";
import AvailabilityRecordSetting from "../components/availabilityRecordSetting";
import AdjustmentRecordSetting from "../components/adjustmentRecordSetting";
import Recurrence from "../components/recurrence";
import TagLabel from "../components/tagLabel";
import Cancellation from "../components/cancellation";
import PackageAddOn from "../components/packageAddOn";
import Sortable from "../components/sortable";
import MultiSelect from "../components/multiSelect";
import Collapse from "../components/collapse"

application.register('application', ApplicationController);
window.getApplicationController = function (application) {
  return application.getControllerForElementAndIdentifier(document.querySelector('body'), 'application');
}

application.register('toggle-field', ToggleField);
application.register('alert', Alert);
window.getAlertController = function (application) {
  return application.getControllerForElementAndIdentifier(document.querySelector('#alert'), 'alert');
}

application.register('responder', Responder);
application.register('prevent-leave-dirty-form', PreventLeaveDirtyForm);
application.register('modal', Modal);
application.register('dropdown', Dropdown);
application.register('input-list', InputList);
application.register('country-city-select', CountryCitySelect);
application.register('country-city-checkbox', CountryCityCheckbox);
application.register('tabs', Tabs);
application.register('calendar', Calendar);
application.register('time-slot', TimeSlot);
application.register('add-on-type-select', AddOnTypeSelect);
application.register('add-on-inventory', AddOnInventory);
application.register('date-range', DateRange);
application.register('availability-record-setting', AvailabilityRecordSetting);
application.register('adjustment-record-setting', AdjustmentRecordSetting);
application.register('recurrence', Recurrence);
application.register('tag-label', TagLabel);
application.register('cancellation', Cancellation);
application.register('package-add-on', PackageAddOn);
application.register('multi-select', MultiSelect);
application.register('collapse', Collapse)

// import page based stimulus controller
require('../components/admin/users/index').default(application);
require('../components/admin/users/new').default(application);
require('../components/admin/hotels/new').default(application);
require('../components/admin/hotels/edit').default(application);
require('../components/admin/hotel_rooms/new').default(application);
require('../components/admin/hotel_rooms/edit').default(application);
require('../components/admin/add_ons/new').default(application);
require('../components/admin/add_ons/edit').default(application);
require('../components/admin/package_groups/new').default(application);
require('../components/admin/package_groups/edit').default(application);
require('../components/admin/packages/new').default(application);
require('../components/admin/packages/edit').default(application);
require('../components/admin/orders/index').default(application);

// import view component based stimulus controller
require('../../components/auto_complete_component_controller').default(application);
require('../../components/admin/permissions_edit_component_controller').default(application);

// Import and register all TailwindCSS Components
import { Autosave, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
import tagLabel from "../components/tagLabel"
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register("sortable", Sortable)

Rails.start();
ActiveStorage.start()

// import all images
require.context('../images', true);

document.addEventListener("turbo:submit-end", (event) => {
  if (event.detail.fetchResponse.response.status == 401) {
    location.reload();
  }
});
