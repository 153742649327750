import { Controller as BaseController } from "@hotwired/stimulus";

function uniques(arr) {
  var a = [];
  for (var i = 0, l = arr.length; i < l; i++)
    if (a.indexOf(arr[i]) === -1 && arr[i] !== "") a.push(arr[i]);
  return a;
}

function selectToggleFieldWithPermission(permission) {
  return document.querySelectorAll(`[data-permissions~=${CSS.escape(permission)}]`)[0];
}

class Controller extends BaseController {
  connect() {
    const inputElements = this.element.querySelectorAll('input[type=hidden]');
    setTimeout(() => {
      inputElements.forEach((el) => this.onChangeValue(el, true));
    });
  }

  onChangeValue(element, isInitial = false) {
    const value = element.target ? element.target.value : element.value;
    if (isInitial && value === 'true') {
      return;
    }

    const toggle = element.srcElement ? element.srcElement.parentElement : element.parentElement;
    if (toggle.dataset.permissionChildren.trim() === '') {
      return;
    }

    const childrenPermissions = toggle.dataset.permissionChildren.split(' ');
    const childrenToggles = childrenPermissions
      .map(selectToggleFieldWithPermission)
      .filter((n) => n !== toggle);
    uniques(childrenToggles).forEach((el) => {
      if (value === 'false') {
        el.dataset.toggleFieldValueValue = false;
        el.dataset.toggleFieldDisabledValue = true;
      } else {
        el.dataset.toggleFieldDisabledValue = false;
      }
    });
  }
}

export default (application) =>
  application.register("components--admin-permissions-edit", Controller);
