import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'back', 'front', 'input'];
  static classes = ['backEnabled', 'backDisabled', 'frontEnabled', 'frontDisabled'];
  static values = { value: Boolean, disabled: Boolean };

  connect() {
    this.inputTargets.forEach((t) => {
      t.value = this.valueValue;
    });
    this.disabledValueChanged();
  }

  toggle(event) {
    event.preventDefault();
    this.valueValue = !this.valueValue;
    this.inputTargets.forEach((t) => {
      t.value = this.valueValue;
      t.dispatchEvent(new Event('input'));
    });
  }

  valueValueChanged() {
    this.inputTargets.forEach((t) => {
      t.value = this.valueValue;
    });
    if (this.valueValue) {
      this.backTarget.classList.add(...this.backEnabledClasses);
      this.frontTarget.classList.add(...this.frontEnabledClasses);
      this.backTarget.classList.remove(...this.backDisabledClasses);
      this.frontTarget.classList.remove(...this.frontDisabledClasses);
    } else {
      this.backTarget.classList.add(...this.backDisabledClasses);
      this.frontTarget.classList.add(...this.frontDisabledClasses);
      this.backTarget.classList.remove(...this.backEnabledClasses);
      this.frontTarget.classList.remove(...this.frontEnabledClasses);
    }
  }

  disabledValueChanged() {
    if (this.disabledValue) {
      this.parentTarget.setAttribute('disabled', '');
      this.backTarget.setAttribute('disabled', '');
    } else {
      this.parentTarget.removeAttribute('disabled');
      this.backTarget.removeAttribute('disabled');
    }
  }
}
