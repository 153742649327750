import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule';
import { Turbo } from '@hotwired/turbo-rails';
import { navigator } from "@hotwired/turbo";
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [
    'calendar',
    'monthInput',
    'prev',
    'next',
    'eventModal',
    'adjustmentTypeModal',
    'recurrenceModal',
    'availabilityRecordFormContainer',
    'deleteAvailabilityRecordForm',
    'deleteAdjustmentRecordForm',
    'availabilityRecordFormButtons',
    'recurrenceForm',
    'switchToEditButton',
    'switchToViewButton',
    'packageGroupAdjustmentRecordFormContainer',
    'packageGroupAdjustmentRecordFormButtons',
    'adjustmentRecordDetailDropdown',
    'packageGroupAdjustmentRecordDetailContainer',
    'emptyPackageGroupAdjustmentRecordDetailContainer',
    'packagePriceDetailDropdown',
    'priceAdjustmentText',
    'totalPriceText',
    'priceAdjustmentCheck',
    'totalPriceCheck',
  ];
  static values = {
    min: String,
    max: String,
    newAvailabilityRecordUrl: String,
    editAvailabilityRecordUrl: String,
    newAdjustmentRecordUrl: String,
    editAdjustmentRecordUrl: String,
    showAdjustmentRecordUrl: String,
    showPackagePriceDetailUrl: String,
    eventsUrl: String,
    showInventory: Boolean,
    disabled: Boolean,
    isEditing: Boolean,
    adjustmentType: String,
    packageEventType: String,
  };

  connect() {
    this.monthInputTarget.setAttribute('min', this.minValue);
    this.monthInputTarget.setAttribute('max', this.maxValue);
    const _this = this;
    const calendar = new Calendar(this.calendarTarget, {
      plugins: [ rrulePlugin, dayGridPlugin ],
      headerToolbar: {
        left: 'title',
        right: '',
      },
      fixedWeekCount: false,
      dayMaxEvents: 1,
      dayCellClassNames: (arg) => {
        const disabledClass = this.disabledValue ? 'disabled' : '';
        const dateString =
          `${arg.date.getFullYear()}-` +
          `${(arg.date.getMonth() + 1).toString().padStart(2, '0')}-` +
          `${(arg.date.getDate()).toString().padStart(2, '0')}`;
        if (window.holidayDates.indexOf(dateString) !== -1) {
          return ['fc-day-holiday', disabledClass];
        } else if ([0, 6].indexOf(arg.date.getDay()) !== -1) {
          return ['fc-day-weekend', disabledClass];
        } else {
          return ['fc-day-weekday', disabledClass];
        }
      },
      eventOrder: '-priority,-createdAt',
      events: {
        url: this.eventsUrlValue,
        extraParams: function () {
          const params = {};
          if (_this.hasPackageEventTypeValue) {
            params.package_event_type = _this.packageEventTypeValue;
          }
          return params
        }
      },
      eventClassNames: function (arg) {
        const event = arg.event;
        return `event-type-${event.extendedProps.type}`;
      },
      eventContent: (arg, createEl) => {
        const event = arg.event;
        if (['price_adjustment', 'package_price_adjustment'].includes(event.extendedProps.type)) {
          return this.eventContentForPriceAdjustment(event, createEl)
        } else if (['empty_price_adjustment', 'package_empty_price_adjustment'].includes(event.extendedProps.type)) {
          return this.eventContentForEmptyPriceAdjustment(event, createEl)
        }
        return createEl('div', { class: `flex flex-row flex-wrap gap-2 p-2 text-xs text-gray-1` },
          [
            createEl('div', {},
            [
              createEl('div', { class: 'p-0 title-weekday hidden' }, event.extendedProps.titles.weekday),
              createEl('div', { class: 'p-0 title-weekend hidden' }, event.extendedProps.titles.weekend),
              createEl('div', { class: 'p-0 title-holiday hidden' }, event.extendedProps.titles.holiday),
            ]),
            this.showInventoryValue
              ? createEl('div', {class: 'text-white px-1 bg-black bg-opacity-40 rounded'},
              `${event.extendedProps.leftQuantity} / ${event.extendedProps.totalQuantity} `
              )
              : null,
          ]
        );
      },
      eventClick: (info) => {
        this.currentEvent = info.event;
        if (this.hasAvailabilityRecordFormContainerTarget) {
          this.availabilityRecordFormContainerTarget.innerHTML = '';
        }
        if (this.hasAvailabilityRecordFormButtonsTarget) {
          this.availabilityRecordFormButtonsTarget.innerHTML = '';
        }
        if (this.hasPackageGroupAdjustmentRecordFormContainerTarget) {
          this.packageGroupAdjustmentRecordFormContainerTarget.innerHTML = '';
        }
        if (this.hasPackageGroupAdjustmentRecordFormButtonsTarget) {
          this.packageGroupAdjustmentRecordFormButtonsTarget.innerHTML = '';
        }
        if (this.hasPackageGroupAdjustmentRecordDetailContainerTarget) {
          this.packageGroupAdjustmentRecordDetailContainerTarget.innerHTML = '';
        }
        if (this.hasRecurrenceFormTarget) {
          this.recurrenceFormTarget.innerHTML = '';
        }

        if (info.event.extendedProps.type === 'empty_price_adjustment') {
          if (this.isEditingValue) {
            this.openAdjustmentTypeModal();
          } else {
            this.openAdjustmentRecordDetailDropdown(info);
          }
        } else if (info.event.extendedProps.type === 'price_adjustment') {
          if (this.isEditingValue) {
            this.openEventModal();
          } else {
            this.openAdjustmentRecordDetailDropdown(info)
          }
        } else if (['package_total_price', 'package_price_adjustment', 'package_empty_price_adjustment'].includes(info.event.extendedProps.type)) {
          this.openPackagePriceDetailDropdown(info)
        } else {
          this.openEventModal();
        }
      }
    });

    calendar.render();
    this.calendarTarget.calendar = calendar;
  }

  eventModalUrl(event) {
    const {id, type} = event.extendedProps;
    if (type === 'empty_price_adjustment') {
      return new URL(this.newAdjustmentRecordUrlValue)
    } else if (type === 'price_adjustment') {
      return new URL(this.editAdjustmentRecordUrlValue.replace(':id', id))
    } else if (type === 'specific') {
      return new URL(this.editAvailabilityRecordUrlValue.replace(':id', id))
    } else {
      return new URL(this.newAvailabilityRecordUrlValue)
    }
  }

  eventContentForPriceAdjustment(event, createEl) {
    return createEl('div', {class: `flex flex-row flex-wrap gap-2 p-2 text-xs text-gray-1`},
      [
        createEl('div', {class: `flex flex-row space-x-1.5`},
          [
            event.extendedProps.isRepeated ? createEl('img', {src: event.extendedProps.repeatIcon}) : null,
            createEl('img', {src: event.extendedProps.adjustmentIcon}),
            createEl('div', {class: 'p-0 title-weekday hidden'}, event.extendedProps.titles.weekday),
            createEl('div', {class: 'p-0 title-weekend hidden'}, event.extendedProps.titles.weekend),
            createEl('div', {class: 'p-0 title-holiday hidden'}, event.extendedProps.titles.holiday),
          ]),
        this.showInventoryValue
          ? createEl('div', {class: 'text-white px-1 bg-black bg-opacity-40 rounded'},
          `${event.extendedProps.leftQuantity} / ${event.extendedProps.totalQuantity} `
          )
          : null,
      ]
    );
  }

  eventContentForEmptyPriceAdjustment(event, createEl) {
    return createEl('div', {class: `flex flex-row space-x-2 p-2 text-xs text-gray-1`},
      [
        createEl('div', {class: `flex flex-row space-x-1.5`},
          [
            createEl('div', {class: 'p-0 title-weekday hidden'}, event.extendedProps.titles.weekday),
            createEl('div', {class: 'p-0 title-weekend hidden'}, event.extendedProps.titles.weekend),
            createEl('div', {class: 'p-0 title-holiday hidden'}, event.extendedProps.titles.holiday),
          ]),
        this.showInventoryValue
          ? createEl('div', {class: 'text-white px-1 bg-black bg-opacity-40 rounded'},
          `${event.extendedProps.leftQuantity} / ${event.extendedProps.totalQuantity} `
          )
          : null,
      ]
    );
  }

  reload() {
    this.calendarTarget.calendar.refetchEvents();
  }

  next() {
    this.calendarTarget.calendar.next();
    const date = this.calendarTarget.calendar.getDate();
    this.monthInputTarget.value = this.dateToMonthInputValue(date);
    this.updateNavigationButtonForDate(date);
  }

  prev() {
    this.calendarTarget.calendar.prev();
    const date = this.calendarTarget.calendar.getDate();
    this.monthInputTarget.value = this.dateToMonthInputValue(date);
    this.updateNavigationButtonForDate(date);
  }

  today() {
    this.calendarTarget.calendar.today();
    const date = this.calendarTarget.calendar.getDate();
    this.monthInputTarget.value = this.dateToMonthInputValue(date);
    this.updateNavigationButtonForDate(date);
  }

  changeMonth(evt) {
    let value = evt.target.value;
    if (value == null || value === '') {
      const today = new Date();
      this.monthInputTarget.value = this.dateToMonthInputValue(today);
      this.calendarTarget.calendar.gotoDate(today);
      this.updateNavigationButtonForDate(today);
    } else {
      if (new Date(this.minValue) > new Date(value)) {
        value = this.minValue;
        evt.target.value = this.minValue;
      } else if (new Date(this.maxValue) < new Date(value)) {
        value = this.maxValue;
        evt.target.value = this.maxValue;
      }
      const date = new Date(value);
      this.calendarTarget.calendar.gotoDate(date);
      this.updateNavigationButtonForDate(date);
    }
  }

  switchToEdit() {
    this.isEditingValue = true;
    this.switchToEditButtonTarget.classList.add('hidden');
    this.switchToViewButtonTarget.classList.remove('hidden');
  }

  switchToView() {
    this.isEditingValue = false;
    this.switchToEditButtonTarget.classList.remove('hidden');
    this.switchToViewButtonTarget.classList.add('hidden');
  }

  dateToMonthInputValue(date) {
    // add some offset to avoid timezone problem
    date.setDate(7);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  }

  updateNavigationButtonForDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const [maxYear, maxMonth] = this.maxValue.split('-').map(i => parseInt(i, 10));
    if ((year > maxYear) || (year === maxYear && month >= maxMonth)) {
      this.nextTarget.setAttribute('disabled', '');
    } else {
      this.nextTarget.removeAttribute('disabled');
    }

    const [minYear, minMonth] = this.minValue.split('-').map(i => parseInt(i, 10));
    if ((year < minYear) || (year === minYear && month <= minMonth)) {
      this.prevTarget.setAttribute('disabled', '');
    } else {
      this.prevTarget.removeAttribute('disabled');
    }
  }

  submitAvailabilityRecordForm() {
    const form = this.availabilityRecordFormContainerTarget.querySelector('form');
    getApplicationController(this.application).startAction();
    navigator.submitForm(form);
  }

  submitAdjustmentRecordForm() {
    const form = this.packageGroupAdjustmentRecordFormContainerTarget.querySelector('form');
    getApplicationController(this.application).startAction();
    navigator.submitForm(form);
  }

  deleteAvailabilityRecord(evt) {
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.deleteAvailabilityRecordFormTarget);
  }

  deleteAdjustmentRecord(evt) {
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.deleteAdjustmentRecordFormTarget);
  }

  openEventModal() {
    this.addEventModalBackground();
    const url = this.eventModalUrl(this.currentEvent);
    const searchParams = url.searchParams;
    searchParams.append('date', this.currentEvent.startStr);
    if (this.hasAdjustmentTypeValue) {
      searchParams.append('adjustment_type', this.adjustmentTypeValue);
    }
    Turbo.visit(url, { historyChanged: true });
    this.eventModalTarget.classList.remove('hidden');
  }

  openRecurrenceModal() {
    this.addRecurrenceModalBackground();
    this.recurrenceModalTarget.classList.remove('hidden');
  }

  openAdjustmentTypeModal() {
    this.addEventModalBackground();
    this.adjustmentTypeModalTarget.classList.remove('hidden');
  }

  initTippy(tippyTarget, contentTarget, showOnCreate=true) {
    if (!tippyTarget._tippy) {
      tippy(tippyTarget, {
        trigger: "click",
        content: contentTarget.innerHTML,
        allowHTML: true,
        arrow: false,
        placement: 'right-start',
        showOnCreate: showOnCreate,
        interactive: true,
        onShow(instance) {
          instance.popper.querySelector('.close-button').addEventListener('click', () => {
            instance.hide();
          });
        },
        onHide(instance) {
          instance.popper.querySelector('.close-button').removeEventListener('click', () => {
            instance.hide();
          });
        },
      })
    }
  }

  openAdjustmentRecordDetailDropdown(info) {
    const {id, type} = this.currentEvent.extendedProps;
    const target = info.jsEvent.target;
    if (type === 'empty_price_adjustment') {
      this.packageGroupAdjustmentRecordDetailContainerTarget.classList.add('hidden');
      this.emptyPackageGroupAdjustmentRecordDetailContainerTarget.classList.remove('hidden');
    } else {
      this.packageGroupAdjustmentRecordDetailContainerTarget.classList.remove('hidden');
      this.emptyPackageGroupAdjustmentRecordDetailContainerTarget.classList.add('hidden');
      const url = new URL(this.showAdjustmentRecordUrlValue.replace(':id', id))
      const searchParams = url.searchParams;
      searchParams.append('date', this.currentEvent.startStr);
      Turbo.visit(url, { historyChanged: true });
    }

    this.initTippy(target, this.adjustmentRecordDetailDropdownTarget);
    info.jsEvent.stopPropagation();
  }

  openPackagePriceDetailDropdown(info) {
    const target = info.jsEvent.target;

    const url = new URL(this.showPackagePriceDetailUrlValue);
    const searchParams = url.searchParams;
    searchParams.append('date', this.currentEvent.startStr);
    Turbo.visit(url, { historyChanged: true });

    this.initTippy(target, this.packagePriceDetailDropdownTarget);
    info.jsEvent.stopPropagation();
  }

  closeEventModal() {
    this.eventModalTarget.classList.add('hidden');
    this.removeEventModalBackground();
  }

  closeAdjustmentTypeModal() {
    this.adjustmentTypeModalTarget.classList.add('hidden');
    this.removeEventModalBackground();
  }

  closeRecurrenceModal() {
    this.recurrenceModalTarget.classList.add('hidden');
    this.removeRecurrenceModalBackground();
  }

  addEventModalBackground() {
    const html = `<div id="event-modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9996;"></div>`;
    document.body.insertAdjacentHTML('beforeend', html);
  }

  removeEventModalBackground() {
    const background = document.querySelector('#event-modal-background');
    background.remove();
  }

  addRecurrenceModalBackground() {
    const html = `<div id="recurrence-modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>`;
    document.body.insertAdjacentHTML('beforeend', html);
  }

  removeRecurrenceModalBackground() {
    const background = document.querySelector('#recurrence-modal-background');
    background.remove();
  }

  adjustmentTypeChange(evt) {
    this.adjustmentTypeValue = evt.target.value;
  }

  switchPackageEventTypeToPriceAdjustment() {
    if (this.packageEventTypeValue != 'priceAdjustment') {
      this.packageEventTypeValue = 'priceAdjustment';
      this.priceAdjustmentTextTarget.classList.remove('hidden');
      this.totalPriceTextTarget.classList.add('hidden');
      this.priceAdjustmentCheckTarget.classList.remove('invisible');
      this.totalPriceCheckTarget.classList.add('invisible');
      this.calendarTarget.calendar.refetchEvents();
    }
  }

  switchPackageEventTypeToTotalPrice() {
    if (this.packageEventTypeValue != 'totalPrice') {
      this.packageEventTypeValue = 'totalPrice'
      this.priceAdjustmentTextTarget.classList.add('hidden');
      this.totalPriceTextTarget.classList.remove('hidden');
      this.priceAdjustmentCheckTarget.classList.add('invisible');
      this.totalPriceCheckTarget.classList.remove('invisible');
      this.calendarTarget.calendar.refetchEvents();
    }
  }
}
