import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'values', 'result', 'search', 'resultPlaceholder']
  static values = {
    maxCount: Number
  }

  connect() {
    if (this.hasMaxCountValue) {
      this.setInputDisabled(this.checkedCount() >= parseInt(this.maxCountValue, 10));
    }
  }

  update() {
    if (this.hasMaxCountValue) {
      this.setInputDisabled(this.checkedCount() >= parseInt(this.maxCountValue, 10));
    }
    if (this.hasResultTarget) {
      this.updateResult()
    }
  }

  checkedCount() {
    if (this.valuesTarget != null) {
      const value = this.valuesTarget.value;
      return value.indexOf(",") === -1
        ? 0
        : value.split(",").map(v => v.trim()).filter(v => v.length > 0).length;
    }

    return this.inputTargets.filter((t) => t.checked).length;
  }

  setInputDisabled(disabled) {
    this.inputTargets.forEach(t => {
      if (disabled && !t.checked) {
        t.setAttribute('disabled', '');
      } else {
        t.removeAttribute('disabled');
      }
    });
  }

  updateResult() {
    const selected = []
    this.inputTargets.forEach(t => {
      if (t.checked && t.labels.length > 0) {
        selected.push(t.labels[0].innerText);
      }
    });
    this.resultTarget.innerText = selected.join(", ");
    if (selected.length > 0 ) {
      this.resultPlaceholderTarget.classList.add('hidden')
    } else {
      this.resultPlaceholderTarget.classList.remove('hidden')
    }
  }

  focusOnSearch() {
    const searchTarget = this.searchTarget;
    setTimeout(() => {
      searchTarget.focus();
    }, 100);
  }

  search(event) {
    const keyword = event.target.value.toLowerCase();

    this.inputTargets.forEach(t => {
      let containKeyword = false
      t.labels.forEach(l => {
        containKeyword = containKeyword || l.innerText.toLowerCase().includes(keyword);
      })
      if (containKeyword) {
        t.parentNode.parentNode.classList.remove('hidden')
      } else {
        t.parentNode.parentNode.classList.add('hidden')
      }
    });
  }
}
