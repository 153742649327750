import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // This input is for detecting dyanmically added elements
  static targets = ['input'];

  connect() {
    this.isDirty = false;
    this.isSubmitting = false;

    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.formMutationObserver = new MutationObserver(() => { this.isDirty = true });
    window.addEventListener('beforeunload', this.onBeforeUnload);
    this.inputs = this.element.querySelectorAll('input,textarea');
    this.inputs.forEach((input) => {
      input.addEventListener('input', this.onInputChange);
      input.addEventListener('change', this.onInputChange);
    });
    this.element.addEventListener('submit', this.onFormSubmit);
  }

  disconnect() {
    this.element.removeEventListener('submit', this.onFormSubmit);
    this.inputs = this.element.querySelectorAll('input');
    this.inputs.forEach((input) => {
      input.removeEventListener('input', this.onInputChange);
      input.removeEventListener('change', this.onInputChange);
    });
    this.inputTargets.forEach((el) => {
      el.removeEventListener('input', this.onInputChange);
      el.removeEventListener('change', this.onInputChange);
    });
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  inputTargetConnected(el) {
    el.addEventListener('input', this.onInputChange);
    el.addEventListener('change', this.onInputChange);
  }

  inputTargetDisconnected(el){
    el.removeEventListener('input', this.onInputChange);
    el.removeEventListener('change', this.onInputChange);
  }

  onInputChange() {
    this.isDirty = true;
  }

  onFormSubmit() {
    this.isSubmitting = true;
  }

  onBeforeUnload(e) {
    if (!this.isDirty || this.isSubmitting) {
      return undefined;
    }

    const confirmationMessage = "If you leave before saving, changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }
}
