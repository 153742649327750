import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['quantityDeductionMode'];

  static values = {
    addOnType: String,
    isLimited: Boolean,
  };

  inventoryChange(evt) {
    this.isLimitedValue = evt.currentTarget.value;
    this.updateQuantityDeductionModeVisibility();
  }

  addOnTypeValueChanged() {
    this.updateQuantityDeductionModeVisibility();
  }

  updateQuantityDeductionModeVisibility() {
    if (['extra_visitor', 'extra_bed', 'room_upgrade'].includes(this.addOnTypeValue) || !this.isLimitedValue) {
      this.quantityDeductionModeTarget.classList.add('hidden');
    } else {
      this.quantityDeductionModeTarget.classList.remove('hidden');
    }
  }
}
