import { Controller as BaseController } from '@hotwired/stimulus';
import { navigator } from "@hotwired/turbo";

class Controller extends BaseController {
  static targets = [
    'form',
    'allowDraftInput',
    'input',
    'submitButton',
    'package'
  ];

  connect() {
    this.packageCount = 0;
  }

  submitForm() {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    this.allowDraftInputTarget.value = false;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }

  allowDraftSubmitForm() {
    this.allowDraftInputTarget.value = true;
    getApplicationController(this.application).startAction();
    navigator.submitForm(this.formTarget);
  }


  addNewPackage(event) {
    event.preventDefault();
    this.packageCount = this.packageCount + 1;

    const url = new URL(event.currentTarget.dataset.addNewPackageUrl);
    url.searchParams.set('package_count', this.packageCount);
    url.pathname = `${url.pathname}.turbo_stream`;
    const urlString = url.toString();
    if (!urlString) return;

    Turbo.visit(urlString, {
      historyChanged: true
    });
  }
}

export default application => application.register('admin-packages-new', Controller);
