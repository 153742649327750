import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'adjustmentTypeSelect',
    'weekdayTitle',
    'weekendTitle',
    'holidayTitle',
    'description'
  ]

  static values = {
    weekdayTitleDeduction: String,
    weekendTitleDeduction: String,
    holidayTitleDeduction: String,
    weekdayTitleAddition: String,
    weekendTitleAddition: String,
    holidayTitleAddition: String,
    additionDescription: String,
    deductionDescription: String,
    adjustmentType: String,
  };

  adjustmentTypeChanged(evt) {
    this.adjustmentTypeValue = evt.target.value;
    this.refreshByAdjustmentType();
  }

  refreshByAdjustmentType() {
    if (this.adjustmentTypeValue === 'addition') {
      this.weekdayTitleTarget.innerText = this.weekdayTitleAdditionValue;
      this.weekendTitleTarget.innerText = this.weekendTitleAdditionValue;
      this.holidayTitleTarget.innerText = this.holidayTitleAdditionValue;
      this.descriptionTargets.forEach(target => target.innerText = this.additionDescriptionValue);
    } else {
      this.weekdayTitleTarget.innerText = this.weekdayTitleDeductionValue;
      this.weekendTitleTarget.innerText = this.weekendTitleDeductionValue;
      this.holidayTitleTarget.innerText = this.holidayTitleDeductionValue;
      this.descriptionTargets.forEach(target => target.innerText = this.deductionDescriptionValue);
    }
  }

  adjustmentTypeSelectTargetConnected(element) {
    this.adjustmentTypeValue = element.selectedOptions[0].value;
    this.refreshByAdjustmentType();
  }
}
