import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['country', 'city']
  static values = {
    url: String,
  }

  updateCity() {
    const url = new URL(this.urlValue);
    url.searchParams.set('input_name', this.cityTarget.name);
    url.searchParams.set('target', this.cityTarget.id);
    url.searchParams.set('country_id', this.countryTarget.value);
    url.pathname = `${url.pathname}.turbo_stream`;
    Turbo.visit(url.toString(), { historyChanged: true });
  }
}
