import { transition } from "../transition";

function getTransitionFromElement(el, key) {
  return {
    base: el.dataset[`${key}TransitionBase`],
    from: el.dataset[`${key}TransitionFrom`],
    to: el.dataset[`${key}TransitionTo`],
    finished: el.dataset[`${key}TransitionFinished`],
    duration: parseInt(el.dataset[`${key}TransitionDuration`], 10),
    delay: parseInt(el.dataset[`${key}TransitionDelay`], 10),
  }
}

export const useTransition = controller => {
  Object.assign(controller, {
    transitions(key) {
      return getTransitionFromElement(this.element, key);
    },
    transitionCancellationTokens() {
      if (this.element.transitionCancellationTokens == null) {
        this.element.transitionCancellationTokens = [];
      }

      return this.element.transitionCancellationTokens;
    },
    transitionTarget() {
      const target = this.element.dataset.transitionTarget;
      if (target == null || target == '') {
        return this.element;
      }

      return this[`${target}Target`];
    },
    runTransition(from, to) {
      transition(
        this.transitionTarget(),
        this.transitions(from),
        this.transitions(to),
        this.transitionCancellationTokens()
      );
    },
    cancelTransitions() {
      this.transitionCancellationTokens().forEach((t) => {
        clearTimeout(t);
      });
    }
  });
};
